<template>
    <vs-popup class="terminalTicketZ_popup" title="Ticket Z" :active.sync="popupActive" fullscreen>
        <div style="min-height:500px;">
            <vs-row>
                <vs-col vs-w="4" style="padding-right:5px;">
                    Date début:
                    <cDate v-model="start_date" class="w-full select-large"/>
                    Date fin:
                    <cDate v-model="end_date" class="w-full select-large"/>
                    <br/>
                    <vs-button @click="viewTicketZ()" class="float-right">Voir</vs-button>

                </vs-col>
                <vs-col vs-w="8" style="padding-left:5px;">
                    <iframe v-bind:src="path" class="w-full" height="800" frameBorder="0"></iframe>
                </vs-col>
            </vs-row>
        </div>

    </vs-popup>
</template>

<style lang="scss">
.terminalTicketZ_popup
{
    iframe{
        width:100%;
    }
}
</style>

<script>

import cDate from '@/components/app/date'
import Fdate from '@/assets/utils/fdate'

export default {
    components:{
        cDate,
	},
    props: {
    },
    data(){
        return{
            uid:'',
            popupActive:false,

            start_date: new Date(),
            end_date: new Date(),

            path:"https://studioconnect.fr/",
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){
        
    },
    methods:{
        openPopup( _uid )
        {
            this.popupActive = true
            this.uid = _uid;
        },
        viewTicketZ()
        {
            this.path = this.$srvApi.buildUrl( 'studio/caddy/ticketz/'+this.uid+"/"+Fdate.getInputDate(this.start_date)+"/"+Fdate.getInputDate(this.end_date) );
        }
    }
}

</script>