<template>
  <div>
      <vs-popup class="terminalTicketZ_popup" title="Extraction du terminal" :active.sync="popupActive"  >
        <div style="min-height:500px;">

            <h1> Choisissez une période : </h1>
            <br/>
            <vs-divider/>
            <br/>
            <vs-row>
                
                    Date debut:
                    <cDate month="1" v-model="start_date" class="w-full select-large"/>
            </vs-row>
            <br/>
            <vs-row>
                
                    Date fin:
                    <cDate month="1" v-model="end_date" class="w-full select-large"/>
                
			</vs-row>
            <br/>
            <vs-divider/>
            <vs-row align="center">
                
                <vs-button @click="download()"> Exporter</vs-button>
                
               
            </vs-row>
          
        </div>

    </vs-popup>
  </div>
</template>


<script>
import Terminal from '@/database/models/terminal';
import Invoice from '@/database/models/invoice';
import Item from '@/database/models/item';
import Member from '@/database/models/member';
import moment from 'moment';
import Utils from '@/assets/utils/utils';
import cDate from '@/components/app/date';
import crypt from 'crypto-js';
import XLSX from 'xlsx'
import jspdf from 'jspdf'
import Studio from '@/database/models/studio'



export default {
    components:{
        Terminal,
        Invoice,
        Item,
        Member,
        Utils,
        cDate,
        Studio,
       
    },
    data(){
        return{
            popupActive:false,
            AdresseE:'',
            nomE:'',
            list:[],
            toExport:[],
            term:'',
            head:["Facture","Date","Adresse emetteur","Nom emetteur","Adresse client","Nom client","Mode de paiement","Type","Libelle","Quantite","Prix unitaire","T.V.A","Total H.T document","Total T.V.A document","Total T.T.C document"],
            start_date:new Date(),
            end_date:new Date(),
            mac:"",
            signature:'',
            fileef:'',
            result:'',
            filename:'',

             studio:{
                type        : '',
                society     : '',
                address     : '',
                city        : '',
                code_postal : '',
                country     : '',
                capital     : '',
                siren       : '',
                rcs_city    : '',
                number_tva  : '',
                phone       : '',
                email       : '',
                mollie      : '',
            },

            devise: {}
            
        }
    },
    mounted() {
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
        })
    },
    watch:{
        start_date:function(){
            this.getClotureq(this.mac)
        },
        end_date:function(){
            this.getClotureq(this.mac)
        }
    },
    
    methods:{

        /*
            - info manquantes : SIREN 
            - nom de l'entreprise
            - propriété de la société ? 
            modifer attestation xs
        */
        
            // Generate PDF  // pas utiliser ici 
            generatePDF(){
               
            
               const doc = new jspdf('p', 'pt', 'A4');

               // image 
                var imgI = new Image();
                imgI.src =require("@/assets/images/documentLogo/isidoors.png")
                doc.setFontSize(11);
                doc.addImage(imgI, "PNG", 200, 20,216,60,"isidoors");
                
                // couleur 
                doc.setTextColor("#DCDCDC")
                doc.text("_________________________________________________________________________",70,108)
                
                // couleur 
                doc.setTextColor("#000000")


                // texte 
                var strArr = doc.splitTextToSize("Attestation individuelle relative à l'utilisation du logiciel ou système de  caisse satisfaisant à des conditions d'inaltérabilité, de sécurisation, de conservation et d'archivage des données (CGI, art. 286, I-3° bis)  ", 450)
                doc.text(strArr, 70, 133);
                var strArr2 = doc.splitTextToSize("Pour le client : " + this.studio.name, 450)
                 doc.text(strArr2,70,188)
                var strArr3 = doc.splitTextToSize("Siren :  "+this.studio.siren, 450)
                doc.text(strArr3, 70 ,203)
                var strArr4 = doc.splitTextToSize("Date d’extraction :  du "+ moment(this.start_date,"").format("DD/MM/YYYY") +" au "+ moment(this.end_date,"").format("DD/MM/YYYY") , 450)
                doc.text(strArr4, 70 ,218)
                var strArr5 = doc.splitTextToSize("Je soussigné, Sébastien Gontal, représentant légal de la société ISIDOORS SAS, éditeur de la solution Studio Connect installée sur l’appareil MAC N° "+this.mac+" , propriété de la société " + this.studio.name,450)
                doc.text(strArr5,70,248)
                var strArr6 = doc.splitTextToSize("Atteste, que les fonctionnalités de caisse installées sur l’appareil désigné  ci dessus, satisfont aux conditions d’inaltérabilité, de sécurisation,  de conservation et d’archivage des données en vue du contrôle de l’administration fiscale, prévues au 3° bis du I de l’article 286 du code général des impôts. ",450)
                doc.text(strArr6,70,303)
                var strArr7 = doc.splitTextToSize("Cette attestation a été produite sur la bases des informations officielles et publiques connues à ce jour, nous nous tiendrions informés des ultérieures modifications et ajusterons notre application par des mises à jour si nécessaire. ",450)
                doc.text(strArr7, 70, 368)
                var strArr8 = doc.splitTextToSize("Signature du représentant légal de l’éditeur du système de caisse", 450)
                doc.text(strArr8, 70, 423)
                
                // signature 

                var imgS = new Image();
                imgS.src =require("@/assets/images/documentLogo/signature.png")
                doc.addImage(imgS, "PNG", 400, 428,156,85,"signature");

                // text 

                var strArr9 = doc.splitTextToSize("Pour effectuer le contrôle de l’intégrité du fichier, veuillez l’extraire depuis l’interface du client.", 450)
                doc.text(strArr9, 70, 533)
                var strArr10 = doc.splitTextToSize("Puis charger le dans la page suivante : http://studioconnect.fr/verificationCloture ", 450)
                doc.text(strArr10,70, 558 )
                var strArr11 = doc.splitTextToSize(" \u2022     Si le fichier à été modifié, les clés de signature ne correspondront pas et s’affichera en ROUGE et ne divulguera pas la clé.", 450)
                doc.text(strArr11, 90, 588)
                var strArr12 = doc.splitTextToSize(" \u2022     Si le fichier est identique, les clés de signature correspondront et s’affichera en VERT en laissant apparaitre la clé.", 450)
                doc.text(strArr12,90, 625 )


                var strArr13 = doc.splitTextToSize("L’algorithme de Hash est calculé sur le SHA 256.",450)
                doc.text(strArr13,70, 660)
                var strArr14= doc.splitTextToSize("Il est rappelé que l’établissement d’une fausse attestation est un délit pénal passible de 3 ans d’emprisonnement et de 45 000 € d’amende (Code pénal, art. 441-1). L'usage d'une fausse attestation est passible des mêmes peines.",450)
                doc.text(strArr14, 70, 682)

                // bas de page 
                // couleur 
                doc.setTextColor("#DCDCDC")
                doc.text("_________________________________________________________________________",70,780)
                
                // couleur 
                doc.setTextColor("#000000")

                // 
                
                var str = doc.splitTextToSize("ISIDOORS SAS, 30 Chemin des falaises 30400 Villeneuve les Avignon, ",450)
                doc.text(str, 112, 800)
                var str2 =doc.splitTextToSize("capital de 50.000€, 804310142 RCS Nîmes, Numéro de TVA : FR51804310142",450)
                doc.text(str2, 100,815)

                doc.save("certificat.pdf")
                
            },



        /*
            
            number : numero de la facture,
            date   : date de la facture,
            rsE    : raison social emetteur 
            AdresseE: Adresse Emmetteur
            RsC    : raison social client
            mtHT   : montant total HT
            mtT    : montant total taxes
            mtC    : montant total TTC
            mD     : mode de reglement
            lib    : libelle 
            qte    : quantite 
            pU     : prix unitaire 
            tH     : total HT
            tVA    : taux TVA 
            
        */
       async getCloture(mac,nom){
           this.term=nom;
           this.mac=mac;
           this.getClotureq(mac)
            
       },
       async download(){
        //    let csvContent = this.toExport;
        //     const data = encodeURI(csvContent);
        //     const link = document.createElement("a");
        //     link.setAttribute("href", data);
        //     link.setAttribute("download", "export1.csv");
        //     link.click();
            const data = this.formatJson(this.head, this.list);
            const auto = true;
            const sig = this.signature;
            const header = this.head;
            const filenames = "Extraction_"+this.term+"_"+ moment(this.start_date,"").format("MM-YYYY") +"_"+(moment(new Date).format("DD-MM-YYYY-HH-mm-ss"))
            const stdio = this.studio;
            const deb = this.start_date;
            const end = this.end_date;
            const mc  = this.mac;

            // console.log("files ", filenames)
            this.filename=filenames;
            import('@/assets/utils/Export2ExcelCloture')
			.then(excel => {
                 excel.export_json_to_excel(
				{
                    siren: stdio.siren,
                    type : stdio.type,
                    deb : deb,
                    end : end, 
                    mac : mc, 
                    client : stdio.name,
                    signature : sig,
					header: header,
					data,
					filename : filenames,
					autoWidth: auto,
					bookType : 'csv',
                    
				})
               
                
            });
            
            
       },
       
        async getSign(e){

          

           
            // console.log(" e : ", e.target.files[0])
            let f = e.target.files[0];

            let reader = new FileReader();
            reader.onload = function(e) {
            var data = new Uint8Array(e.target.result);
            
            var workbook = XLSX.read(data, {type: 'array', cellDates: true });
           
            // console.log("wb ", workbook)

            let sheetName = workbook.SheetNames[0]
            /* DO SOMETHING WITH workbook HERE */
            // console.log(workbook);
            let worksheet = workbook.Sheets[sheetName];
            
            // console.log ( "wok ", worksheet)
            
            let n = XLSX.utils.sheet_to_json(worksheet);
            // console.log(n);
            let sha ="";
            for (let i in n){
                // console.log(" st : ", sha)
                // console.log ( "ni ", n[i])
                // console.log(" sj" , JSON.stringify(n[i]))
                sha = crypt.MD5(sha + JSON.stringify(n[i])).toString()
                // console.log(" -- 1 sha", sha)

            }
            // console.log(" result : ", crypt.SHA256(sha).toString())
            // this.result = crypt.SHA256(sha).toString()
          };
          reader.readAsArrayBuffer(f);

           
            
                
        },
        
       formatJson (filterVal, jsonData)
		{
			let data = jsonData.map(v => filterVal.map(function(j) 
			{
				//Correction de champs ICI
				//if(j == 'uid')
				//	v[j] = undefined;
				return v[j];
			}));

			//BUG! enlever les duplications !!
			filterVal = Utils.removeDuplicate(filterVal);
			//Fin
			return data;
		},
        
        async getClotureq(mac){

            ///////// studio 
            
            let studioR = await Studio.AwaitGetAllMemory();
            
             //   this.studio = JSON.parse(JSON.stringify( list_studio[ this.$srvApi.getStudio() ] ));
            
            this.studio = JSON.parse(JSON.stringify( studioR[ this.$srvApi.getStudio() ] ));
            // console.log(" this studio : ",this.studio)


           
            this.popupActive=true;
            let invoice = await Invoice.AwaitGetAllMemoryKey(mac)
            // console.log(" c : ", invoice )
            
            let stringmd5="";

            let debut = moment(this.start_date,"").format("YYYY-MM")
            let fin = moment(this.end_date,"").format("YYYY-MM")
            let rows =[];
            let num =[]
            for (let i in invoice ){
                num.push({number:invoice[i].number, uid:invoice[i].uid})
            }
            num.sort((a,b)=> a.number-b.number);
            // console.log(" num ", num )
            let invoiceSort=[];
           for ( let b =0; b<num.length; b++){
               invoiceSort.push(invoice[num[b].uid])
           }
           invoice=invoiceSort;

            for (let i in invoice ){
                // let row =[];

                
                    // creation de la facture 
                    let datee   = moment(invoice[i].createAt,"").format("YYYY-MM");
                    
                if(moment(datee.toString()).isBetween(debut.toString(),fin.toString()) || moment(datee.toString()).isSame(debut.toString()) || moment(datee.toString()).isSame(fin.toString())){
                        // numero de la facture 
                        let number = invoice[i].number; 

                        // article de la facture 
                        let lib=[];
                        let tVA=[];
                        let pU =[];
                        let it =[]; 
                        let cpt =[];
                        let root = this;
                        let mD="";
                        


                        let uid = this.$srvApi.getLoginUserUid();
                        Member.getMemory(uid, (mbr)=>
                        {
                            if ( uid != undefined){
                                this.AdresseE=mbr.address +" "+ mbr.city+ " " + mbr.postal_code;
                                this.nomE= mbr.first_name+" "+mbr.last_name
                            }
                            else{
                                this.AdresseE="Membre supprimé";
                                this.nomE="Membre supprimé";
                            }
                        
                        }); 
                        
                        // type de facture : 
                        let type="";


                        // montant total TTC
                        let totalTTCu =[];
                        let totalTTC =0;
                        // total HT 
                        let tH=0;

                        let items= await Item.AwaitGetTabAllMemoryKey(invoice[i].uid)
                        for(let j in items){
                            // console.log(" items : ", items)
                            let com=0;
                        // for (let j in items){
                            let x = root.getIndex(it, items[j].name)
                            if( x==-1){
                                it.push(items[j].name);
                                cpt.push(1);
                                lib.push(items[j].name);
                                tVA.push(items[j].tva);
                                pU.push(items[j].prix);
                                
                            }
                            else{
                                cpt[x]=cpt[x]+1;
                            }
                            
                            
                            if (com<1){
                                type=items[j].type;
                                // console.log(" type : ", items[j].type)
                                com++;
                            }
                            
                            tH=tH+items[j].prix;
                            if (items[j].tva<0){
                                let a = (items[j].tva/100)*-1;
                                let b = (items[j].prix)*-1;
                                let cal = parseFloat(b+b*a);
                                totalTTCu.push(cal*(-1));
                                totalTTC=parseFloat(totalTTC+cal*(-1));

                            }
                            else{
                                
                                totalTTCu.push((items[j].prix + items[j].prix*(items[j].tva/100)).toFixed(this.devise.nb_decimal))
                                totalTTC=parseFloat(totalTTC+(items[j].prix + items[j].prix*(items[j].tva/100)))
                            }
                            
                            
                            
                        }
                        totalTTC=totalTTC.toFixed(this.devise.nb_decimal)
                        let auxi=[];
                        let autre=false;
                        if(lib.length>1){
                            autre=true;
                            for ( let x=1; x<lib.length; x++ ){
                                let vv={
                                
                                    "Facture" : parseInt(number), 
                                    "Date"    : " ", 
                                    "Adresse emetteur" :" ",
                                    "Nom emetteur" : " ", 
                                    "Adresse client": " ",
                                    "Nom client" : " ",
                                    "Mode de paiement" : " ",
                                    "Type"            : " ", 
                                    "Libelle" : lib[x],
                                    "Quantite"      : cpt[x],
                                    "Prix unitaire" : pU[x], 
                                    "T.V.A"     : tVA[x],
                                    "Total H.T document"    : " ", 
                                    "Total T.V.A document" : " ",
                                    "Total T.T.C document"    : " ",
                                   
                                    
                                
                                }
                                auxi.push(vv)
                            }
                        }    
                        // lib =  lib.join('\n')
                        // pU = pU.join('\n')
                        // tVA= tVA.join('\n')
                        // cpt = cpt.join('\n')
                        
                        if(type==""){
                            type="Reçu"
                        }
                        else if(type=="av"){
                            type="Avoir"
                        }
                        else if(type=="fa"){
                            type="Facture"
                        }
                    
                        
                        //  moyen de paiement 
                        if (invoice[i].pay_cb!=0 ){
                            mD=mD+" "+"Carte Bancaire";
                        }
                        if (invoice[i].pay_money!=0){
                            mD=mD+" "+"Espece";
                        }
                        if (invoice[i].pay_check!=0 ){
                            mD=mD+" "+"Cheque";
                        }
                        if (invoice[i].pay_prele!=0){
                            mD=mD+" "+"Prelevement";
                        }
                        if (invoice[i].pay_dif!=0 ){
                            mD=mD+" "+"Differe";
                        }
                        if (invoice[i].pay_cb==0 && invoice[i].pay_money==0 && invoice[i].pay_check==0 && invoice[i].pay_prele==0 && invoice[i].pay_dif==0){
                            mD=mD+" "+"Aucun"
                            
                        }
                        // total 
                        //let tH = (invoice[i].pay_cb + invoice[i].pay_money + invoice[i].pay_check + invoice[i].pay_dif + invoice[i].pay_prele).toFixed(this.devise.nb_decimal)
                        
                        
                        // infos clients 

                        let membre = await Member.AwaitGetAllMemory();
                        let aC;
                        let client;
                        if ( membre[invoice[i].member]!=undefined){
                            aC = membre[invoice[i].member].address +" "+ membre[invoice[i].member].city+ " " + membre[invoice[i].member].postal_code;
                            client = membre[invoice[i].member].first_name+" "+membre[invoice[i].member].last_name;
                        }
                        else{
                            aC="Membre supprimé";
                            client="Membre supprimé";
                        }
                        let ecart = (totalTTC-tH).toFixed(this.devise.nb_decimal);
                        
                        // let Datee = moment(invoice[i].createAt,"").format("DD-MM-YYYY HH:mm:ss");
                        
                        let Datee = invoice[i].createAt
                        
                        if(autre==true){
                            let v ={
                                
                                "Facture" : parseInt(number), 
                                "Date"    : Datee, 
                                "Adresse emetteur" : this.studio.address+" "+ this.studio.code_postal+" "+this.studio.city,
                                "Nom emetteur" : this.studio.name, 
                                "Adresse client": aC,
                                "Nom client" : client,
                                "Mode de paiement" : mD,
                                "Type"            : type, 
                                "Libelle" : lib[0],
                                "Quantite"      : cpt[0],
                                "Prix unitaire" : pU[0], 
                                "T.V.A"     : tVA[0],
                                "Total H.T document"    : parseFloat(tH.toFixed(this.devise.nb_decimal)), 
                                "Total T.V.A document" : parseFloat(ecart),
                                "Total T.T.C document"    : parseFloat(totalTTC),
                            }
                            // console.log(" v 1", v)
                            // console.log(" -- 3 ",JSON.stringify(v) )
                            stringmd5 = crypt.MD5(stringmd5 + JSON.stringify(v)).toString();
                            // if (stringmd5=="410e25a3a914df9be7e29ef7c4fa8cbd")
                            //    console.log(" -- 1 ", stringmd5, " : ", v)

                            rows.push(v)
                        }
                        else{
                            let v ={
                                
                                "Facture" : parseInt(number), 
                                "Date"    : Datee, 
                                "Adresse emetteur" :  this.studio.address+" "+ this.studio.code_postal+" "+this.studio.city,
                                "Nom emetteur" : this.studio.name, 
                                "Adresse client": aC,
                                "Nom client" : client,
                                "Mode de paiement" : mD,
                                "Type"            : type, 
                                "Libelle" : lib[0],
                                "Quantite"      : cpt[0],
                                "Prix unitaire" : pU[0], 
                                "T.V.A"     : tVA[0],
                                "Total H.T document"    : parseFloat(tH.toFixed(this.devise.nb_decimal)), 
                                "Total T.V.A document" : parseFloat(ecart),
                                "Total T.T.C document"    : parseFloat(totalTTC),
                               
                                
                                
                                
                            }
                            // console.log(" -- v ",JSON.stringify(v) )
                            
                            stringmd5 = crypt.MD5(stringmd5 + JSON.stringify(v)).toString();
                            // if (stringmd5=="410e25a3a914df9be7e29ef7c4fa8cbd")
                                // console.log(" -- 2 ", stringmd5, " : ", v)
                            rows.push(v)
                        }
                    
                        
                        if (autre==true){
                            for(let x =0;x<auxi.length; x++){
                                // console.log(" -- 3 ",JSON.stringify(auxi[x]) )
                                stringmd5 = crypt.MD5(stringmd5 + JSON.stringify(auxi[x])).toString();
                                // if (stringmd5=="410e25a3a914df9be7e29ef7c4fa8cbd")
                                    // console.log(" -- 3 ", stringmd5, ": ",auxi[x])
                                rows.push(auxi[x])
                            }
                        }
                    
                    // permet l'espace 
                        let vSpace ={
                                
                                "Facture" : " ", 
                                "Date"    : " ", 
                                "Adresse emetteur" : " ",
                                "Nom emetteur" : " ", 
                                "Adresse client": " ",
                                "Nom client" : " ",
                                "Mode de paiement" : " ",
                                "Type"            : " ", 
                                "Libelle" : " ",
                                "Quantite"      : " ",
                                "Prix unitaire" : " ", 
                                "T.V.A"     : " ",
                                "Total H.T document"    : " ", 
                                "Total T.V.A document" : " ",
                                "Total T.T.C document"    : " ",
                                
                                
                                
                            }
                            // console.log(" v", vSpace)
                            // console.log(" -- 3 ",JSON.stringify(vSpace) )
                            stringmd5 = crypt.MD5(stringmd5 + JSON.stringify(vSpace)).toString();
                        //    if (stringmd5=="410e25a3a914df9be7e29ef7c4fa8cbd")
                                // console.log(" -- 4 ", stringmd5," : ",vSpace)
                            rows.push(vSpace)
                       
                       let vTitle ={
                                
                                "Facture" : "Facture", 
                                "Date"    : "Date",
                                "Adresse emetteur" : "Adresse emetteur",
                                "Nom emetteur" : "Nom emetteur", 
                                "Adresse client": "Adresse client",
                                "Nom client" : "Nom client",
                                "Mode de paiement" : "Mode de paiement",
                                "Type"            : "Type", 
                                "Libelle" : "Libelle",
                                "Quantite"      : "Quantite",
                                "Prix unitaire" : "Prix unitaire",
                                "T.V.A"     : "T.V.A", 
                                "Total H.T document"    : "Total H.T document", 
                                "Total T.V.A document" : "Total T.V.A document",
                                "Total T.T.C document"    : "Total T.T.C document",
                                
                                
                            }
                            // console.log(" v", vTitle)
                            // console.log(" -- 3 ",JSON.stringify(vTitle) )
                            stringmd5 = crypt.MD5(stringmd5 + JSON.stringify(vTitle)).toString();
                            // if (stringmd5=="410e25a3a914df9be7e29ef7c4fa8cbd")
                                // console.log(" -- 5 ", stringmd5, " : ",vTitle)
                            rows.push(vTitle)
                    
                }
            
            }

            // console.log(" -- ", rows)
            // console.log("lines : ", lines )
            this.list=rows;
            this.signature=crypt.SHA256(stringmd5).toString()
            
            return rows
            
        },
        getObjectCSV(rows){
            let headers = ["Facture","Date","Adresse emetteur","Nom emetteur","Adresse client","Nom client","Mode de paiement","Type","Libelle","Quantite","Prix unitaire","T.V.A","Total H.T document","Total T.T.C document"]

            let lines=[];
            lines.push(headers.join(','));

            for(const i in this.list){
                //let b = [];
                    
                 
                    const values = headers.map(header=>{
                        
                        return `"${rows[i][header]}"`
                    })
                    
                    lines.push(values.join(','))
                // }
                //lines.push(values.join(','));
                
                //lines.push(b.join(','))
            }
            // console.log(" ? ",lines.join('\n') )
            return lines.join('\n')
        },
        getIndex(list,x){
                let d =-1;
                // permet de recuper l'index de ce qui est chercher
                for (let i in list){
                    if(list[i]==x){
                        
                        return d=i;
                    }
                }
                return d;
        },
        
    }
    
}
</script>

<style>

</style>

